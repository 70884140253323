import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../components/tables/tableContext/TableContext";
import { AwbHistoryDataInterface } from "../../../../redux/interfaces/general.interface";

const statusValues = [
  { value: 0, label: "Creat" },
  { value: 1, label: "Colet preluat (validat)" },
  {
    value: 2,
    label: "Colet 'posibil' preluat de livrator (coletul este intr-un bin)",
  },
  { value: 3, label: "Colet adus la nod" },
  { value: 4, label: "Colet 'posibil' in bin adus in nod" },
  {
    value: 5,
    label:
      "Colet 'posibil' incarcat in masina intranod (coletul este intr-un bin)",
  },
  {
    value: 6,
    label: "Colet confirmat in masina - nu este intr-un bin (ex: frig)",
  },
  {
    value: 7,
    label:
      "Colet 'posibil' descarcat in nod din masina intranod (bin-ul a fost descarcat intr-un nod)",
  },
  { value: 8, label: "Colet scanat in nod" },
  { value: 9, label: "Colet incarcat pentru livrare" },
  { value: 10, label: "Colet livrat" },
  {
    value: 11,
    label:
      "Colet nelivrat (motivatie in nomenclatorul de motive pentru care nu a livrat la client)",
  },
];

const getStatusValue = (status: number) => {
  const currentStatus = statusValues.find((item) => item.value === status);

  return currentStatus?.label;
};

export const awbHistoryTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "AWB",
    value: "doc_trans",
    minW: 125,
  },
  {
    label: "Pct. preluare",
    value: "pctpreluare",
    minW: 125,
  },
  {
    label: "Pct. livrare",
    value: "pctlivrare",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Adresa",
    value: "adresa",
    minW: 125,
  },
  {
    label: "Nod-Destinatie",
    value: "nodDestinatie",
    minW: 125,
  },
  {
    label: "Tel. contact",
    value: "tel_contact",
    minW: 125,
  },
  {
    label: "Status",
    value: "status",
    minW: 125,
    withFilter: statusValues,
  },
  {
    label: "Tip colete",
    value: "tip_colete",
    minW: 125,
  },
];

export const getAwbTableData = (data: AwbHistoryDataInterface[]): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_doctrans,
      crudData: item,
      data: [
        {
          title: item.doc_trans,
        },
        {
          title: item.pctpreluare,
        },
        {
          title: item.pctlivrare,
        },
        {
          title: item.adresa,
        },
        {
          title: item.nodDestinatie,
        },
        {
          title: item.tel_contact,
        },
        {
          badges: [
            {
              badgeText: getStatusValue(item.status),
              badgeColor: "light-info",
            },
          ],
        },
        {
          badges: item.tip_colete.map((tip) => {
            return {
              badgeText: `${tip.denumire} x ${tip.count_tip_ambalaj}`,
              badgeColor: "primary",
            };
          }),
        },
      ],
    };
  });

import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  TableContext,
  TableData,
  TableFilterDataItem,
  TableHeaderCategoryConfig,
} from "./TableContext";

type Props = {
  children: React.ReactNode;
};

const TableProvider = ({ children }: Props) => {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableHeader, setTableHeader] = useState<TableHeaderCategoryConfig[]>(
    [],
  );
  const [tablePagination, setTablePagination] = useState({
    page: 1,
    rowsPerPage: 25,
  });
  const [tableSearch, setTableSearch] = useState<object | null>(null);
  const [tableFilter, setTableFilter] = useState<object | null>(null);
  const [tableOrder, setTableOrder] = useState<object | null>(null);
  const [tableGlobalSearch, setTableGlobalSearch] = useState("");
  const [tableGlobalFilter, setTableGlobalFilter] = useState("");
  const [globalFilterOptions, setGlobalFilterOptions] = useState<
    null | TableFilterDataItem[]
  >(null);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const [debouncedTableContextValues, setDebouncedTableContextValues] =
    useState<any>(null);

  // we debounce the table context values to avoid multiple requests
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTableContextValues({
        tableSearch,
        tableFilter,
        tablePagination,
      });
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [tablePagination, tableSearch, tableFilter]);

  const handleSetTablePagination = useCallback(
    (
      callBack: (prev: { page: number; rowsPerPage: number }) => {
        page: number;
        rowsPerPage: number;
      },
    ) => {
      setTablePagination(callBack);
    },
    [],
  );

  const deleteItem = useCallback((id: string | number) => {
    setTableData((prev) => prev.filter((item) => item.id !== id));
  }, []);

  const handleTableFilter = useCallback(
    (key: string, value: string | number) => {
      if (
        typeof key === "string" &&
        (typeof value === "string" || typeof value === "number")
      ) {
        setTableFilter((prev) => ({ ...prev, [key]: value }));
        setTablePagination((prev) => ({ ...prev, page: 1 }));
      }
    },
    [],
  );

  const handleTableSearch = useCallback(
    (key: string, value: string | number) => {
      if (
        typeof key === "string" &&
        (typeof value === "string" || typeof value === "number")
      ) {
        setTableSearch((prev) => ({ ...prev, [key]: value }));
        setTablePagination((prev) => ({ ...prev, page: 1 }));
      }
    },
    [],
  );

  const handleTableOrder = useCallback((key: string) => {
    if (typeof key === "string") {
      // @ts-ignore
      setTableOrder((prev) => {
        // @ts-ignore
        if (prev && prev[key] === "asc") {
          return { [key]: "desc" };
        }
        return { [key]: "asc" };
      });
    }
  }, []);

  const handleCheckboxClick = useCallback(
    (
      crudData: any,
      checkboxId?: string | number,
      tableItemId?: string | number,
    ) => {
      if (crudData) {
        const itemId = checkboxId ?? tableItemId ?? crudData?.id;

        setSelectedItems((prev) => {
          if (prev.some((item) => item.id === itemId)) {
            return prev.filter((item) => item.id !== itemId);
          } else {
            return [...prev, { id: itemId, ...crudData }];
          }
        });
      }
    },
    [],
  );

  const handleCheckAllItems = useCallback(() => {
    if (selectedItems.length === tableData.length) {
      setSelectedItems([]);
      return;
    }
    const allItems = tableData.map((item) => item.crudData);
    setSelectedItems(allItems);
  }, [selectedItems, tableData]);

  const tableDataWithCheckedItems = useMemo(() => {
    if (selectedItems.length === 0) {
      return tableData;
    }
    return tableData.map((item) => {
      return {
        ...item,
        data: item.data.map((tableItem) => {
          if (tableItem.checkbox) {
            const isSelectedItem = selectedItems.some(
              (selectedItem) => selectedItem.id === item.id,
            );
            return {
              ...tableItem,
              checkbox: {
                ...tableItem.checkbox,
                isSelected: isSelectedItem,
              },
            };
          }
          return tableItem;
        }),
      };
    });
  }, [tableData, selectedItems]);

  return (
    <TableContext.Provider
      value={{
        tableData: tableDataWithCheckedItems,
        setTableData,
        tableHeader,
        setTableHeader,
        tablePagination,
        handleSetTablePagination,
        tableSearch,
        setTableSearch,
        handleTableSearch,
        tableFilter,
        setTableFilter,
        handleTableFilter,
        tableOrder,
        handleTableOrder,
        deleteItem,
        tableGlobalSearch,
        setTableGlobalSearch,
        tableGlobalFilter,
        setTableGlobalFilter,
        globalFilterOptions,
        setGlobalFilterOptions,
        handleCheckboxClick,
        selectedItems,
        setSelectedItems,
        handleCheckAllItems,
        debouncedTableContextValues,
      }}>
      {children}
    </TableContext.Provider>
  );
};

export default TableProvider;

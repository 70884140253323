import { TableHeaderCategoryConfig } from "../../../../../components/tables/tableContext/TableContext";
import { ClientWorkingPointsDataInterface } from "../../../../../redux/interfaces/general.interface";

export const expeditionScheduleWorkingPointsTableHeader: TableHeaderCategoryConfig[] =
  [
    {
      value: "searchAdresa",
      label: "Adresa",
      minW: 125,
      withSearch: true,
    },
    {
      value: "searchJudet",
      label: "Judet",
      withSearch: true,
    },
    {
      value: "searchLocalitate",
      label: "Localitate",
      withSearch: true,
      minW: 125,
    },
    {
      value: "searchPctLucru",
      label: "Punct de livrare",
      withSearch: true,
      minW: 125,
    },

    {
      value: "Status",
      minW: 125,
    },
  ];

export const getExpeditionScheduleWorkingPointsTableData: any = (
  data: ClientWorkingPointsDataInterface[],
) =>
  data?.map((item) => {
    return {
      id: item.idPctLucru,
      crudData: item,
      data: [
        {
          subtitle: item.adresa,
        },
        {
          title: item.judet,
        },
        {
          title: item.localitate,
        },
        {
          title: item.pctDeLivrare,
        },

        {
          badges: [
            {
              badgeText: item.status ? "Validat" : "Nevalidat",
              badgeColor: item.status ? "light-success" : "light-danger",
            },
          ],
        },
      ],
    };
  });

import React from "react";
import { KTSVG } from "./KTSVG";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import SensorsIcon from "@mui/icons-material/Sensors";
import AddIcon from "@mui/icons-material/Add";

// Import svg Icon
// Add icon type constant to svgTypes
// add imported svg component to SVGIcons

export type SvgIconType =
  | "KEYBOARD_ARROW_DOWN"
  | "KEYBOARD_ARROW_UP"
  | "DOCUMENT_WITH_DOWNLOAD"
  | "NONCONFORMING"
  | "DELETE"
  | "EDIT"
  | "BULLET_LINK"
  | "MENU"
  | "PILL"
  | "DOUBLE_ARROW_RIGHT"
  | "LOCK_CLOSE"
  | "LOCK_OPEN"
  | "DOCUMENTS"
  | "SENSORS"
  | "ADD";

export const svgTypes: any = {
  KEYBOARD_ARROW_DOWN: KeyboardArrowDownIcon,
  KEYBOARD_ARROW_UP: KeyboardArrowUpIcon,
  DOCUMENT_WITH_DOWNLOAD: "/media/svg/reception/document.svg",
  NONCONFORMING: "/media/svg/reception/nonconforming.svg",
  DELETE: DeleteIcon,
  EDIT: EditIcon,
  BULLET_LINK: "/media/icons/bullet_link.svg",
  MENU: "/media/icons/duotune/general/gen025.svg",
  PILL: "/media/icons/duotune/medicine/med002.svg",
  DOUBLE_ARROW_RIGHT: "/media/icons/duotune/arrows/arr080.svg",
  LOCK_CLOSE: "/media/icons/lock_close.svg",
  LOCK_OPEN: "/media/icons/lock_open.svg",
  DOCUMENTS: DescriptionIcon,
  SENSORS: SensorsIcon,
  ADD: AddIcon,
};

const SvgTypesValues = Object.keys(svgTypes);

const SvgIcon = (props: { type: SvgIconType; [key: string]: any }) => {
  const isAvailable = SvgTypesValues.includes(props?.type);

  if (!isAvailable) {
    console.warn("Svg icon is not available, please check svgTypes");
    return <></>;
  }

  const getSvgIcon = () => {
    const SvgPath = svgTypes[props.type];

    if (typeof SvgPath !== "string") {
      return <SvgPath {...props} />;
    }

    return (
      <KTSVG path={SvgPath} className="svg-icon-1" fill="black" {...props} />
    );
  };

  return getSvgIcon();
};

export default SvgIcon;

import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import ContentCard from "../../../components/misc/ContentCard";
import useRoutesHelper from "../../../hooks/useRoutesHelper";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import FormButtons from "../../../components/misc/FormButtons";
import { Formik } from "formik";
import TextInput from "../../../components/misc/TextInput";
import Checkbox from "../../../components/misc/Checkbox";
import Dropdown from "../../../components/misc/Dropdown";

import { setSelectedClientWorkingPoint } from "../../../redux/reducer/general.reducer";
import { fetchCreateOrUpdateClientWorkingPoint } from "../../../redux/api/general.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";
import countiesAndLocalities from "../../../constants/judete.json";

interface initialValuesInterface {
  idPctLucruErp?: number | null;
  adresa: string;
  pctDeLivrare: string;
  localitate: string;
  judet: string;
  codPostal: string;
  persContact: string;
  telContact: string;
  withPickupPoint: boolean;
}

const initialValues: initialValuesInterface = {
  idPctLucruErp: null,
  adresa: "",
  pctDeLivrare: "",
  judet: "-",
  localitate: "-",
  codPostal: "",
  persContact: "",
  telContact: "",
  withPickupPoint: false,
};

const formikSchema = Yup.object({
  idPctLucruErp: Yup.number().required("ID_ERP este obligatoriu"),
  adresa: Yup.string().required("Denumirea este obligatorie"),
  pctDeLivrare: Yup.string().required("Punctul de livrare este obligatoriu"),
  judet: Yup.string().notOneOf(["-", ""], "Judetul este obligatoriu"),
  localitate: Yup.string().notOneOf(["-", ""], "Localitatea este obligatorie"),
  codPostal: Yup.string().required("Codul postal este obligatoriu"),
  persContact: Yup.string().required("Persoana de contact este obligatorie"),
  telContact: Yup.string().required("Telefonul de contact este obligatoriu"),
});

function ClientWorkingPointsOrganise({ withUpdate }: { withUpdate?: boolean }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    routeParams: { id, routeId },
  } = useRoutesHelper();

  const [isSaving, setIsSaving] = useState(false);

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);

  const {
    user: {
      data: { id_client },
    },

    general: { selectedClientWorkingPoint },
  } = useAppSelector((state) => ({
    general: state.general,
    user: state.user,
  }));

  useEffect(() => {
    if (withUpdate && routeId) {
      if (selectedClientWorkingPoint) {
        setFormikValues((prev) => ({
          ...prev,
          idPctLucru: selectedClientWorkingPoint.idPctLucru,
          idPctLucruErp: selectedClientWorkingPoint.idPctLucruErp,
          adresa: selectedClientWorkingPoint.adresa,
          pctDeLivrare: selectedClientWorkingPoint.pctDeLivrare,
          judet: selectedClientWorkingPoint.judet.toUpperCase() || "-",
          localitate: selectedClientWorkingPoint.localitate || "-",
          codPostal: selectedClientWorkingPoint.codPostal,
          persContact: selectedClientWorkingPoint.persContact,
          telContact: selectedClientWorkingPoint.telContact,
          withPickupPoint: selectedClientWorkingPoint.withPickupPoint,
        }));
      }
    }
  }, [routeId, withUpdate, selectedClientWorkingPoint, id_client]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedClientWorkingPoint(null));
    };
  }, [dispatch]);

  const handleSave = async (value: initialValuesInterface) => {
    setIsSaving(true);

    const resultAction = await dispatch(
      fetchCreateOrUpdateClientWorkingPoint(value),
    );

    if (
      checkEndpointStatus(
        resultAction,
        fetchCreateOrUpdateClientWorkingPoint,
      ) !== endpointStatus.pending
    ) {
      setIsSaving(false);
    }

    if (
      checkEndpointStatus(
        resultAction,
        fetchCreateOrUpdateClientWorkingPoint,
      ) === endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const getLocaltiesForSelectedCounty = (county?: {
    id: string;
    label: string;
    localitati: {
      nume: string;
      simplu?: string;
      comuna?: string;
    }[];
  }) => {
    if (!county) {
      return [];
    }

    return (
      county?.localitati.map((locality: { nume: string }) => ({
        label: locality.nume,
        id: locality.nume,
      })) || []
    );
  };

  const countiesFormatted = useMemo(() => {
    return countiesAndLocalities.judete.map((county) => ({
      id: county.id,
      label: county.id,
      localitati: county.localitati,
    }));
  }, []);

  return (
    <ContentCard
      cardTitle={
        withUpdate
          ? `Organizare punctul de lucru ${id}`
          : "Creaza punct de lucru"
      }>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={formikSchema}
        onSubmit={handleSave}>
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          getFieldProps,
          handleSubmit,
        }) => {
          const selectedCounty = countiesFormatted.find(
            (county) => county.id === values.judet,
          );

          const localitiesFormatted =
            getLocaltiesForSelectedCounty(selectedCounty);

          const selectedLocality = localitiesFormatted?.find(
            (locality: { id: string }) => locality.id === values.localitate,
          );

          return (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              <TextInput
                label="ID_ERP "
                name="idPctLucruErp"
                error={errors["idPctLucruErp"]}
                touched={touched["idPctLucruErp"]}
                inputProps={getFieldProps("idPctLucruErp")}
                type="number"
              />
              <TextInput
                label="Punct de livrare"
                name="pctDeLivrare"
                error={errors["pctDeLivrare"]}
                touched={touched["pctDeLivrare"]}
                inputProps={getFieldProps("pctDeLivrare")}
              />
              <TextInput
                label="Adresa"
                name="adresa"
                error={errors["adresa"]}
                touched={touched["adresa"]}
                inputProps={getFieldProps("adresa")}
              />
              <Dropdown
                title="Judet"
                data={countiesFormatted}
                singleValue={
                  selectedCounty || {
                    label: "-",
                    id: "-",
                  }
                }
                setSingleValue={(value) => setFieldValue("judet", value?.id)}
                error={errors["judet"]}
                touched={touched["judet"]}
              />
              {localitiesFormatted?.length > 0 && (
                <Dropdown
                  title="Localitate"
                  data={localitiesFormatted}
                  singleValue={
                    selectedLocality || {
                      label: "-",
                      id: "-",
                    }
                  }
                  setSingleValue={(value) =>
                    setFieldValue("localitate", value?.id)
                  }
                  error={errors["localitate"]}
                  touched={touched["localitate"]}
                />
              )}

              <TextInput
                type="number"
                label="Cod postal"
                name="codPostal"
                error={errors["codPostal"]}
                touched={touched["codPostal"]}
                inputProps={getFieldProps("codPostal")}
              />
              <TextInput
                label="Persoana de contact"
                name="persContact"
                error={errors["persContact"]}
                touched={touched["persContact"]}
                inputProps={getFieldProps("persContact")}
              />
              <TextInput
                type="tel"
                label="Telefon de contact"
                name="telContact"
                error={errors["telContact"]}
                touched={touched["telContact"]}
                inputProps={getFieldProps("telContact")}
              />
              <Checkbox
                name="withPickupPoint"
                label="Este si punct de ridicare?"
                checked={values.withPickupPoint}
                onChange={(checked) =>
                  setFieldValue("withPickupPoint", checked)
                }
                labelAlign="left"
              />

              <FormButtons
                goBackLabel="Anuleaza"
                goNextLabel="Salveaza"
                handleGoBack={handleCancel}
                nextButtonDisabled={isSubmitting}
                nextButtonLoading={isSaving}
              />
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default ClientWorkingPointsOrganise;

import React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { DropdownValue } from ".";
import clsx from "clsx";
import Text from "../../../styleguide/Text";
import LoadingIndicator from "../LoadingIndicator";

const radioIcon = <RadioButtonUncheckedIcon fontSize="small" />;
const radioCheckedIcon = <RadioButtonCheckedIcon fontSize="small" />;

const classes = clsx("form-control form-control-solid mb-3 mb-lg-0");

export default function SingleDropdown({
  data,
  singleValue,
  setSingleValue,
  name,
  title = " ",
  className,
  style,
  placeholder,
  isLoading,
  disabled,
  withVerticalSpacer,
  touched,
  error,
}: {
  data: DropdownValue[];
  singleValue?: DropdownValue | null;
  setSingleValue?: (value: DropdownValue | null) => void;
  onChange?: (value: string | number) => void;
  name?: string;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  isLoading?: boolean;
  disabled?: boolean;
  withVerticalSpacer?: boolean;
  touched?: boolean;
  error?: string;
}) {
  const handleOnSelect = (_: any, values: any) => {
    if (values && setSingleValue) {
      setSingleValue(values);
    }
  };
  return (
    <div
      className={`fv-row mb-7 w-100 ${
        withVerticalSpacer ? "px-1" : ""
      } ${className}`}>
      {title && (
        <label
          htmlFor={`${name ?? title}-single-checkbox-label`}
          className="form-label fs-6 fw-bolder text-dark">
          <Text variant="body1" isBold>
            {title}
          </Text>
        </label>
      )}
      <Autocomplete
        id={`${name ?? title}-single-checkbox-label`}
        style={style}
        fullWidth
        disabled={disabled}
        options={data}
        value={singleValue}
        loading={isLoading}
        onChange={handleOnSelect}
        clearIcon={null}
        getOptionLabel={(option) => option.label.toString()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Checkbox
              icon={radioIcon}
              checkedIcon={radioCheckedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes}
            sx={{
              "& fieldset": { border: "none" },
            }}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <LoadingIndicator color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {touched && error && (
        <div className="fv-plugins-message-container">
          <Text variant="body1" color="red" role="alert" className="mt-2">
            {error}
          </Text>
        </div>
      )}
    </div>
  );
}

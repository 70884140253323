import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../components/tables/tableContext/TableContext";
import { UndeliveredAwbDataInterface } from "../../../../redux/interfaces/expedition.interface";

export const undeliveredAwbTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "AWB",
    value: "doc_trans",
    minW: 125,
  },
  {
    label: "Pct. preluare",
    value: "pctpreluare",
    minW: 125,
  },
  {
    label: "Pct. livrare",
    value: "pctlivrare",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Adresa",
    value: "adresa",
    minW: 125,
  },
  {
    label: "Nod-Destinatie",
    value: "nodDestinatie",
    minW: 125,
  },
  {
    label: "Tel. contact",
    value: "tel_contact",
    minW: 125,
  },
  {
    label: "Tip colete",
    value: "tip_colete",
    minW: 125,
  },
];

export const getUndeliveredAwbTableData = (
  data: UndeliveredAwbDataInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_doctrans,
      crudData: item,
      data: [
        {
          title: item.doc_trans,
        },
        {
          title: item.pctpreluare,
        },
        {
          title: item.pctlivrare,
        },
        {
          title: item.adresa,
        },
        {
          title: item.nodDestinatie,
        },
        {
          title: item.tel_contact,
        },
        {
          badges: item.tip_colete.map((tip) => {
            return {
              badgeText: `${tip.denumire} x ${tip.count_tip_ambalaj}`,
              badgeColor: "primary",
            };
          }),
        },
      ],
    };
  });

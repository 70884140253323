import React, { Dispatch, SetStateAction } from "react";

export interface BadgeType {
  badgeText?: string | number;
  badgeColor?:
    | "light-danger"
    | "light-success"
    | "light-warning"
    | "light-info"
    | "light-primary"
    | "danger"
    | "success"
    | "warning"
    | "info"
    | "primary";
  className?: string;
  style?: React.CSSProperties;
  badgeTooltip?:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal;
}

export interface TableItemType {
  img?: string;
  title?: string | number;
  titleStyle?: any;
  subtitle?: string | number;
  subtitleStyle?: any;
  badges?: BadgeType[];
  config?: any;
  checkbox?: {
    id?: number | string;
    isSelected: boolean;
  };
}
export interface TableData {
  id: number | string;
  data: TableItemType[];
  crudData?: any;
  extraData?: TableItemType[][];
}

export interface TableHeaderCategoryConfig {
  value: string;
  label?: string;
  minW?: number;
  maxW?: number;
  withOrder?: boolean;
  withSearch?:
    | boolean
    | {
        placeholder?: string;
        withoutSearchIcon?: boolean;
        withoutSearchClear?: boolean;
        searchDelay?: number;
      };
  withFilter?: {
    value: string | number;
    label: string | number;
  }[];
  withDatePicker?:
    | {
        minDate?: Date;
        maxDate?: Date;
        placeholder?: string;
        withClearData?: boolean;
      }
    | boolean;
}

export interface TablePaginationInterface {
  page: number;
  rowsPerPage: number;
}

export interface TableActions {
  tableSearch: any;
  tablePagination: TablePaginationInterface;
  globalFilterOptions: TableFilterDataItem[] | null;
  tableGlobalSearch: string;
  tableFilter: any;
  selectedItems: any[];
  tableContext: TableContextType;
}

export interface TableFilterDataItem {
  label: string;
  value: number;
}

type TableContextType = {
  tableData: TableData[];
  setTableData: Dispatch<SetStateAction<TableData[]>>;
  tableHeader: TableHeaderCategoryConfig[];
  setTableHeader: (value: TableHeaderCategoryConfig[]) => void;
  tablePagination: { page: number; rowsPerPage: number };
  handleSetTablePagination: (
    callBack: (prev: { page: number; rowsPerPage: number }) => {
      page: number;
      rowsPerPage: number;
    },
  ) => void;
  tableSearch: any;
  setTableSearch: React.Dispatch<React.SetStateAction<object | null>>;
  handleTableSearch: (key: string, value: string | number) => void;
  tableFilter: any;
  setTableFilter: React.Dispatch<React.SetStateAction<object | null>>;
  handleTableFilter: (key: string, value: string | number) => void;
  tableOrder: any;
  handleTableOrder: (key: string) => void;
  deleteItem: (value: string | number) => void;
  tableGlobalSearch: string;
  setTableGlobalSearch: (value: string) => void;
  tableGlobalFilter: string;
  setTableGlobalFilter: (value: string) => void;
  globalFilterOptions:
    | {
        label: string;
        value: number;
      }[]
    | null;
  setGlobalFilterOptions: Dispatch<
    SetStateAction<TableFilterDataItem[] | null>
  >;
  handleCheckboxClick: (
    crudData: object,
    tableItemId?: string | number,
    checkboxId?: string | number,
  ) => void;
  selectedItems: any[];
  setSelectedItems: Dispatch<SetStateAction<any[]>>;
  handleCheckAllItems: () => void;
  debouncedTableContextValues: any;
};

export const TableContext = React.createContext<TableContextType | undefined>(
  undefined,
);

import { useMemo } from "react";
import { crudRoutes, expeditionRoutes, generalRoutes } from "./routesConstants";
import userRoles from "../constants/userRoles";

interface DashboardCategories {
  title: string;
  subtitle?: string;
  subtitleValue?: string;
  categories: {
    id: string;
    title: string;
    path: string;
    subtitle?: string;
    value?: string | number;
    isIncreasing?: boolean;
  }[];
  navigationLinks?: {
    link: string;
    label: string;
  }[];
}

function useAppMenuRoutes() {
  const transportRoutes = useMemo(
    () => [
      {
        id: userRoles.PREVIEW_USERS,
        title: "Istoric AWB",
        path: `/`,
      },
      {
        id: userRoles.CREATE_USER,
        title: "Creeaza AWB",
        path: `/${generalRoutes.createAwb}`,
      },
      {
        id: userRoles.CREATE_USER,
        title: "Creeaza BIN",
        path: `/${generalRoutes.createBin}`,
      },
    ],
    [],
  );

  const clientWorkingPoints = useMemo(
    () => [
      {
        id: userRoles.SCHEDULE_WORKING_POINTS,
        title: "Puncte de lucru",
        path: `/${expeditionRoutes.clientWorkingPoints}`,
      },
      {
        id: userRoles.WORKING_POINTS_ORGANIZE,
        title: "Creeaza punct de lucru",
        path: `/${expeditionRoutes.clientWorkingPoints}/${crudRoutes.create}`,
      },
      {
        id: userRoles.UNDELIVERED_AWB,
        title: "AWB-uri nelivrate",
        path: `/${expeditionRoutes.undeliveredAwb}`,
      },
    ],
    [],
  );

  const categories: DashboardCategories[] = useMemo(
    () =>
      [
        {
          title: "Comenzi",
          categories: transportRoutes,
        },
        {
          title: "Clienti",
          categories: clientWorkingPoints,
        },
      ].filter((category) => category.categories.length > 0),
    [transportRoutes, clientWorkingPoints],
  );

  return categories;
}

export default useAppMenuRoutes;
